import { easeOutExpo, clamp, passiveOption, throttle } from './index';

class Spin {
    constructor(span) {
        this._span = span;
        this._innerSpan = span.children[0];
        this._maxSpinSpeed = 50;
        this._friction = 0.93;
        this._currentVelocity = 0;
        this._flickSpeed = 0;
        this._startTime = null;
        this._duration = null;
        this._rotation = 0;
        this._totalRotation = 0;
        this._resetTimer = null;
        this._spinRaf = null;
        this._resetRaf = null;
        this._resetStartTime = null;

        this.enable();
    }

    handleSpin = throttle(() => {
        const newSpeed = this._currentVelocity + this._flickSpeed;

        this._startTime = Date.now();
        // Arbitrary. Just play with duration multiplier
        this._duration = clamp(Math.abs(newSpeed) * 200, 0, 5000);
        this._maxSpinSpeed = clamp(Math.abs(newSpeed), 1, 50);

        window.cancelAnimationFrame(this._resetRaf);
        window.cancelAnimationFrame(this._spinRaf);
        clearTimeout(this._resetTimer);

        this._spin(newSpeed);
    })

    enable() {
        const passive = passiveOption();

        this._span.addEventListener('mouseover', this.handleSpin, passive);
        this._span.addEventListener('touchstart', this.handleSpin, passive);
    }

    disable() {
        const passive = passiveOption();

        this._span.removeEventListener('mouseover', this.handleSpin, passive);
        this._span.removeEventListener('touchstart', this.handleSpin, passive);
    }

    adjustSpeed(speed) {
        this._flickSpeed = speed * (1 - this._friction);
    }

    _resetBack(complete, startingRotation = this._totalRotation) {
        if (complete) {
            this._innerSpan.style.transform = 'rotateY(0deg)';
            this._rotation = 0;
            this._totalRotation = 0;
            return;
        }

        // const totalSpins = Math.ceil(startingRotation / 360);
        const totalDuration = 2000;
        const currentTime = Date.now() - this._resetStartTime;
        const duration = currentTime > totalDuration ? currentTime : totalDuration;
        const easeMultiplier = easeOutExpo(currentTime, 0, 1, duration);
        const increment = startingRotation * easeMultiplier;

        this._resetRaf = window.requestAnimationFrame(() => {
            const totalRotation = startingRotation - increment;
            const degrees = totalRotation % 360;

            this._innerSpan.style.transform = `rotateY(${degrees}deg)`;

            this._totalRotation = totalRotation;
            this._rotation = degrees;
            this._resetBack(easeMultiplier === 1, startingRotation);
        });
    }

    _spin(speed, complete) {
        if (Math.abs(speed) === 0 || complete) {
            this._resetTimer = setTimeout(() => {
                this._currentVelocity = 0;
                this._resetStartTime = Date.now();
                this._resetBack();
            }, 500);

            return;
        }

        const currentTime = Date.now() - this._startTime;
        const duration = currentTime > this._duration ? currentTime : this._duration;
        const easeMultiplier = easeOutExpo(currentTime, 0, 1, duration);
        const direction = speed < 0 ? -1 : 1;

        this._currentVelocity = speed;

        this._spinRaf = window.requestAnimationFrame(() => {
            const newSpeed = speed * (1 - easeMultiplier);
            const increment = this._maxSpinSpeed * (1 - easeMultiplier) * direction;
            const newDeg = (this._rotation + increment) % 360;

            this._totalRotation += increment;

            // console.log(newSpeed, newDeg, currentTime, '/', duration);

            this._innerSpan.style.transform = `rotateY(${newDeg}deg)`;
            this._rotation = newDeg;
            // console.log(this._rotation, this._totalRotation);
            this._spin(newSpeed * (1 - easeMultiplier), easeMultiplier === 1);
        });
    }
}

export default class Spinner {
    constructor(elementOrId) {
        this._container = typeof elementOrId === 'string' ? document.getElementById(elementOrId) : elementOrId;
        this._container.style.perspective = '20em';
        this._container.style['-webkit-perspective'] = '20em';
        this._spins = [];

        this._enabled = false;

        this._lastMouseMove = null;
        this._lastMouseX = null;

        this.setup();
        this.enable();
    }

    handleMouseMove = (e) => {
        const evt = e.type === 'touchmove' ? e.touches[0] : e;

        if (this._lastMouseMove === null) {
            this._lastMouseMove = Date.now();
            this._lastMouseX = evt.clientX;
            return;
        }

        const now = Date.now();
        const dt =  now - this._lastMouseMove;
        const dx = evt.clientX - this._lastMouseX;

        const speed = Math.round(dx / (dt || 1) * 100);

        this._spins.forEach((s) => s.adjustSpeed(speed));

        this._lastMouseMove = now;
        this._lastMouseX = evt.clientX;
    }

    setup() {
        this._container.style.transformOrigin = '50% 0';
        this._spins = [new Spin(this._container)];
    }

    enable() {
        if (this._enabled) {
            return;
        }

        const passive = passiveOption();

        this._lastMouseMove = null;
        this._lastMouseX = null;
        window.addEventListener('mousemove', this.handleMouseMove, passive);
        window.addEventListener('touchmove', this.handleMouseMove, passive);

        this._spins.forEach((spin) => {
            spin.enable();
        });

        this._enabled = true;
    }

    disable() {
        if (!this._enabled) {
            return;
        }

        const passive = passiveOption();

        window.removeEventListener('mousemove', this.handleMouseMove, passive);
        window.removeEventListener('touchmove', this.handleMouseMove, passive);

        this._spins.forEach((spin) => {
            spin.disable();
        });

        this._enabled = false;
    }

    destroy() {
        this.disable();
        this._spins = [];
    }
}
