export function clamp(val, min, max) {
    return Math.min(Math.max(val, min), max);
}

// t: current time
// b: beginning value
// c: change in value (destination value - beginning value)
// d: duration
// s: overshoot ammount (optional - used only on *back easing)

/* eslint-disable */
export function easeOutBack(t, b, c, d, s) {
    if (s == undefined) s = 1.70158;
    return c * ((t = t / d - 1) * t * ((s + 1) * t + s) + 1) + b;
}

export function easeInExpo(elapsed, initialValue, amountOfChange, duration) {
	return elapsed === 0 ? initialValue : amountOfChange * Math.pow(2, 10 * (elapsed / duration - 1)) + initialValue;
}

export function easeOutExpo(elapsed, initialValue, amountOfChange, duration) {
	return elapsed === duration
		? initialValue + amountOfChange
		: amountOfChange * (-Math.pow(2, -10 * elapsed / duration) + 1) + initialValue;
}

export function easeInCubic(elapsed, initialValue, amountOfChange, duration) {
	return amountOfChange * (elapsed /= duration) * elapsed * elapsed + initialValue;
}

export function easeOutCubic(elapsed, initialValue, amountOfChange, duration) {
	return amountOfChange * ((elapsed = elapsed / duration - 1) * elapsed * elapsed + 1) + initialValue;
}
/* eslint-enable */

export function alterExternalLinks() {
    Array.from(document.querySelectorAll('a')).forEach((link) => {
        if (link.hostname !== window.location.hostname) {
            link.target = '_blank';
            link.rel = 'nofollow noopener';
        }
    });
}

// Inclusive
export function randomNumberBetween(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export function randomItem(arr) {
    return arr[randomNumberBetween(0, arr.length - 1)];
}

export function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? [
              parseInt(result[1], 16),
              parseInt(result[2], 16),
              parseInt(result[3], 16),
          ]
        : null;
}

export function rgbToHex(r, g, b) {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`; // eslint-disable-line
}

export function interpolate(start, end, steps, count) {
    const s = start;
    const e = end;
    const final = s + ((e - s) / steps) * count;
    return Math.floor(final);
}

let passiveSupported = null;

export function passiveOption() {
    if (passiveSupported !== null) {
        return passiveSupported ? { passive: true } : false;
    }

    try {
        const options = Object.defineProperty({}, 'passive', {
            get() {
                passiveSupported = true;
                return undefined;
            },
        });

        window.addEventListener('test', options, options);
        window.removeEventListener('test', options, options);
    } catch (err) {
        passiveSupported = false;
    }

    if (passiveSupported) {
        return { passive: true };
    }

    return false;
}

export function debounce(fn, wait = 100) {
    let timer;

    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(fn, args);
        }, wait);

        return timer;
    };
}

// https://stackoverflow.com/a/27078401/1048847
export function throttle(func, options = {}) {
    let context;
    let args;
    let result;
    let timeout = null;
    let previous = 0;
    const wait = options.wait || 100;

    if (!options) {
        options = {};
    }

    const later = function() {
        // eslint-disable-line
        previous = options.leading === false ? 0 : Date.now();
        timeout = null;
        result = func.apply(context, args);

        if (!timeout) {
            context = null;
            args = null;
        }
    };

    return function() {
        const now = Date.now();

        if (!previous && options.leading === false) {
            previous = now;
        }
        const remaining = wait - (now - previous);

        context = this;
        args = arguments;
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            result = func.apply(context, args);
            if (!timeout) {
                context = args = null;
            }
        } else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining);
        }
        return result;
    };
}
