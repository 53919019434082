import React, { useEffect } from 'react'
import Logo from '../images/logo.svg'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Spinner from '../utils/Spinner'
import RainbowJumper from '../utils/RainbowJumper'

import * as styles from './index.module.css';

export default function IndexPage({ data }) {
    const logoId = 'logo';
    const descriptionId = 'description';
    const description = data.site.siteMetadata.description;

    useEffect(() => {
        const spinner = new Spinner(logoId);
        const jumper = new RainbowJumper(descriptionId, {
            jumpColor: window.getComputedStyle(document.documentElement).getPropertyValue('--purple').trim(),
            // jumpDelayFn: (i, total) => -Math.pow(i - Math.floor(total / 2), 2) + total,
            jumpDelayFn: (i, total) => i * 10,
            jumpDuration: 600,
            colorDuration: 300
        });

        function handleClick() {
            jumper.jump();
        }

        document.body.addEventListener('click', handleClick);

        return () => {
            spinner.destroy();
            jumper.destroy();
            document.body.removeEventListener('click', handleClick);
        }
    }, []);

    return (
        <Layout className={styles.container}>
            <Seo title="Home" />
            <div id={logoId}>
                <Logo className={styles.logo} />
            </div>
            <h1 className={styles.title}>OK COKER</h1>
            <h3 id={descriptionId} className={styles.description}>{description}</h3>
        </Layout>
    )
}


export const query = graphql`
    {
        site {
            siteMetadata {
                description
            }
        }
    }
`
