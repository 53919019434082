import { easeInExpo, easeOutExpo, clamp, passiveOption } from './index';

class Jumper {
    constructor(span, options = {}) {
        this._span = span;
        this._options = options
        this._jumpHeight = options.jumpHeight || 10;
        this._jumpDelay = options.jumpDelay || 0;
        this._currentTranslate = 0;
        this._startTime = null;
        this._colorDuration = options.colorDuration || 200;
        this._duration = options.duration || 300;
        this._resetTimer = null;
        this._colorTimer = null;
        this._jumpRaf = null;
        this._resetRaf = null;
        this._resetStartTime = null;
    }

    jump() {
        if (this._startTime !== null) {
            return;
        }

        const translate = clamp(this._currentTranslate, 0, this._jumpHeight);

        window.cancelAnimationFrame(this._resetRaf);
        window.cancelAnimationFrame(this._jumpRaf);
        clearTimeout(this._resetTimer);

        this._jump(translate);
    }

    color(delay = 0) {
        clearTimeout(this._colorTimer);
        const transition = this._colorDuration;
        const totalTime = transition + delay;

        this._span.style.transition = `color ${transition}ms ease-in ${delay}ms`;

        if (this._options.jumpColor) {
            this._span.style.color = this._options.jumpColor;
        }

        this._colorTimer = setTimeout(() => {
            // this._span.style.transition = 'color 0.8s ease-out';
            this._span.style.transition = `color 0.8s ease-out ${totalTime}ms`
            this._span.style.color = null;
        }, totalTime);
    }

    _resetBack(complete, startingHeight = this._currentTranslate) {
        if (complete) {
            this._startTime = null;
            this._currentTranslate = 0;
            // this._span.style.transform = 'translateY(0)';
            return;
        }

        const totalDuration = this._duration * 0.6;
        const currentTime = Date.now() - this._resetStartTime;
        const duration = currentTime > totalDuration ? currentTime : totalDuration;
        const easeMultiplier = easeInExpo(currentTime, 0, 1, duration);
        const increment = startingHeight * easeMultiplier;

        this._resetRaf = window.requestAnimationFrame(() => {
            const height = startingHeight - increment;
            // if (this._jumpDelay === 0) {
            //     console.log(height);
            // }
            this._span.style.transform = `translateY(${height}px)`;
            this._currentTranslate = height;
            this._resetBack(easeMultiplier === 1, startingHeight);
        });
    }

    _jump(complete) {
        if (complete) {
            this._resetStartTime = Date.now();
            this._resetBack();
            return;
        }

        const now = Date.now();

        if (this._startTime === null) {
            this._startTime = now;
            this.color(this._jumpDelay);
        }

        if (now - this._startTime < this._jumpDelay) {
            this._jumpRaf = window.requestAnimationFrame(() => {
                this._jump(complete);
            });
            return;
        }

        const currentTime = now - this._startTime - this._jumpDelay;
        const duration = currentTime > this._duration ? currentTime : this._duration;
        const easeMultiplier = easeOutExpo(currentTime, 0, 1, duration);

        this._jumpRaf = window.requestAnimationFrame(() => {
            const newTranslate = -(this._jumpHeight * easeMultiplier);

            // if (this._jumpDelay === 0) {
                //     console.log(newTranslate);
            // }

            this._span.style.transform = `translateY(${newTranslate}px)`;

            this._currentTranslate = newTranslate;
            this._jump(easeMultiplier === 1);
        });
    }
}

export default class RainbowJumper {
    constructor(elementOrId, options = {}) {
        this._container = typeof elementOrId === 'string' ? document.getElementById(elementOrId) : elementOrId;
        this._container.style.transform = 'translateZ(0)';
        this._options = options;
        this._originalText = this._container.textContent;
        this._jumpDelayFn = options.jumpDelayFn || ((i) => i * 20);
        this._jumpers = [];

        const rect = this._container.getBoundingClientRect();
        this._containerWidth = Math.round(rect.width);
        this._containerOffsetX = Math.round(rect.left);

        this._enabled = false;

        this.setup();
        this.enable();
    }

    handleContainerMouseMove = (e) => {
        const evt = e.type === 'touchmove' ? e.touches[0] : e;
        const fill = clamp(evt.pageX - this._containerOffsetX, 0, this._containerWidth) / this._containerWidth;
        const index = clamp(Math.floor(this._jumpers.length * fill), 0, this._jumpers.length - 1);

        this._jumpers[index].color();
    }

    handleWindowResize = () => {
        clearTimeout(this._resizeTimer);
        this._resizeTimer = setTimeout(() => {
            const rect = this._container.getBoundingClientRect();

            this._containerWidth = Math.round(rect.width);
            this._containerOffsetX = Math.round(rect.left);
        }, 200);
    }

    setup() {
        const lettersHTML = this._originalText.split('').map((char) => {
            if (char.trim()) {
                return `<span style="display: inline-block;">${char}</span>`;
            }

            return char;
        }).join('');

        this._container.innerHTML = lettersHTML;

        const letters = [].slice.call(this._container.children);
        this._jumpers = letters.map((span, i) => {
            return new Jumper(span, {
                duration: this._options.jumpDuration,
                colorDuration: this._options.colorDuration,
                jumpColor: this._options.jumpColor,
                jumpDelay: this._jumpDelayFn(i, letters.length)
            });
        });
    }

    enable() {
        if (this._enabled) {
            return;
        }

        const passive = passiveOption();

        window.addEventListener('resize', this.handleWindowResize, passiveOption);

        this._container.addEventListener('mousemove', this.handleContainerMouseMove, passive);
        this._container.addEventListener('touchmove', this.handleContainerMouseMove, passive);

        this._enabled = true;
    }

    disable() {
        if (!this._enabled) {
            return;
        }

        const passive = passiveOption();

        window.removeEventListener('resize', this.handleWindowResize, passiveOption);

        this._container.removeEventListener('mousemove', this.handleContainerMouseMove, passive);
        this._container.removeEventListener('touchmove', this.handleContainerMouseMove, passive);

        this._enabled = false;
    }

    jump() {
        this._jumpers.forEach((jumper) => {
            jumper.jump();
        });
    }

    destroy() {
        this.disable();
        this._container.textContent = this._originalText;
        this._jumpers = [];
    }
}
